body {
  margin: 0;
  background-color: #f2f2f2;
}

@media (max-width: 480px) {
  .td {
    display: block;
    min-width: 162px !important;
    max-width: 516px !important;
    width: 100%;
  }

  .social {
    text-align: start !important;
    padding: 5px 0 0 !important;
  }

  .social a {
    padding: 0 5px 0 0 !important;
  }

  .website {
    text-align: start !important;
    padding: 0 0 4px !important;
  }
}
