@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1678px !important;
  }
}

@media (max-width: 480px) {
  .MuiContainer-maxWidthLg {
    height: 100px !important;
  }
}
